import React, { useState } from 'react';
import classNames from 'classnames';

import AccordionTab, { AccordionTabType } from './AccordionTab';

import styles from './Accordion.module.scss';

/* Local constants & types
============================================================================= */
const getAccordionInitState = (tabs: AccordionTabType[]) =>
  tabs.find(({ show }) => Boolean(show))?.id;

/* Props - <Accordion />
============================================================================= */
type Props = {
  tabs: AccordionTabType[];
  className?: string;
};

/* <Accordion />
============================================================================= */
const Accordion: React.FC<Props> = ({ tabs, className }) => {
  const [activeId, setActiveId] = useState(getAccordionInitState(tabs));

  return (
    <div className={classNames(styles.accordion, className)}>
      {tabs.map((tab) => (
        <AccordionTab
          key={tab.id}
          id={tab.id}
          header={tab.header}
          body={tab.body}
          show={activeId === tab.id}
          onClick={(id) =>
            id !== activeId ? setActiveId(id) : setActiveId(undefined)
          }
        />
      ))}
    </div>
  );
};

export default Accordion;
