import React, { useEffect, useRef, useState } from 'react';
import classNames from 'classnames';

import styles from './Accordion.module.scss';
import { useMaxHeight } from 'common/hooks/useMaxHeight';

/* Local constants & types
============================================================================= */
export type AccordionTabType = {
  id: string;
  header: React.ReactElement | string;
  body: React.ReactElement | string;
  show?: boolean;
};

/* Props - <AccordionTab />
============================================================================= */
type Props = {
  onClick: (id: string) => void;
} & AccordionTabType;

/* <AccordionTab />
============================================================================= */
const AccordionTab: React.FC<Props> = ({ id, header, body, show, onClick }) => {
  const ref = useRef<HTMLDivElement>(null);
  const contentMaxHeight = useMaxHeight(ref);

  return (
    <div className={styles.item}>
      <h2 id={`heading-${id}`} className={styles.header}>
        <button
          className={classNames(styles.btn, { [styles.active]: show })}
          type="button"
          onClick={() => onClick(id)}
          aria-expanded={show}
          aria-controls={`collapse-${id}`}>
          {header}
          <i className="icon" aria-hidden="true"></i>
        </button>
      </h2>
      <div
        id={`collapse-${id}`}
        className={classNames(styles.collapse, {
          [styles.show]: show,
          collapse: !show,
        })}
        aria-labelledby={`heading-${id}`}
        style={{ height: show ? `${contentMaxHeight}px` : 0 }}
        ref={ref}>
        <div className={styles.body}>{body}</div>
      </div>
    </div>
  );
};

export default AccordionTab;
