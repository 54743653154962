import { RefObject, useEffect, useState } from 'react';

const useMaxHeight = (ref: RefObject<HTMLDivElement>): number => {
  const [contentMaxHeight, setContentMaxHeight] = useState(0);
  useEffect(() => {
    const calcContentMaxHeight = () => {
      ref && setContentMaxHeight(ref.current?.scrollHeight ?? 0);
    };

    calcContentMaxHeight();

    window.addEventListener("resize", () => calcContentMaxHeight());

    return () => window.removeEventListener("resize", calcContentMaxHeight);
  }, [ref, contentMaxHeight]);

  return contentMaxHeight;
};

export { useMaxHeight };
