import React, { useImperativeHandle, useRef, useState } from 'react';
import classNames from 'classnames';
import { CSSTransition } from 'react-transition-group';

import useConfirm from 'common/hooks/useConfirm';
import Button from 'components/shared/buttons/Button';
import Portal from '../../portal/Portal';

import styles from './BModal.module.scss';

/* Local constants & types
============================================================================= */
export type ModalRefType = {
  toggle: () => void;
  confirmDialog: () => void;
};

const Wrapper: React.FC<{
  isEmbedded?: boolean;
  children: React.ReactNode;
}> = ({ isEmbedded, children }) => {
  if (!isEmbedded) {
    return <Portal container="#modal">{children}</Portal>;
  }

  return <>{children}</>;
};

/* Props - <Modal />
============================================================================= */
type Props = {
  title?: string | React.ReactNode;
  footer?: string | React.ReactNode;
  isEmbedded?: boolean;
  isPrompt?: boolean;
  children?: React.ReactNode;
};

/* <BModal />
============================================================================= */
const BModal: React.ForwardRefRenderFunction<ModalRefType, Props> = (
  { title, footer, isEmbedded, isPrompt, children },
  forwardedRef
) => {
  const [show, setShow] = useState(false);
  const nodeRef = useRef(null);
  const [confirmDialog, handleConfirm, handleCancel] = useConfirm();

  useImperativeHandle(
    forwardedRef,
    () => {
      return {
        toggle: () => setShow((prevState) => !prevState),
        confirmDialog: () => {
          setShow((prevState) => !prevState);
          return confirmDialog();
        },
      };
    },
    []
  );

  return (
    <CSSTransition
      in={show}
      timeout={300}
      nodeRef={nodeRef}
      classNames={{
        enter: styles.modalEnter,
        enterActive: styles.modalEnterActive,
        enterDone: styles.modalEnterDone,
        exit: styles.modalExit,
        exitActive: styles.modalExitActive,
      }}>
      <Wrapper isEmbedded={isEmbedded}>
        {show && (
          <div
            className={classNames(styles.modalBackdrop, styles.fade, {
              [styles.show]: show,
            })}></div>
        )}
        <div
          className={classNames(styles.modal, styles.fade, {
            [styles.show]: show,
          })}
          // className={classNames(styles.modal)}
          tabIndex={-1}
          role="dialog"
          ref={nodeRef}
          aria-hidden={!show}>
          <div className={styles.modalDialog} role="document">
            <div className={styles.modalContent}>
              <div className={styles.modalHeader}>
                {title ?? null}

                <button
                  onClick={() => {
                    handleCancel();
                    setShow(false);
                  }}
                  type="button"
                  className={styles.close}
                  aria-label="Close">
                  <span aria-hidden="true">×</span>
                </button>
              </div>
              <div className={styles.modalBody}>{children}</div>
              {footer ? (
                <div className={styles.modalFooter}>{footer}</div>
              ) : null}
              {isPrompt && (
                <div className={styles.modalFooter}>
                  <Button
                    className="w-full"
                    variant="link"
                    type="button"
                    onClick={() => {
                      handleCancel();
                      setShow((prevState) => !prevState);
                    }}>
                    Відмінити
                  </Button>
                  <Button
                    onClick={() => {
                      handleConfirm();
                      setShow((prevState) => !prevState);
                    }}
                    className="w-full"
                    variant="primary"
                    type="button">
                    Підтвердити
                  </Button>
                </div>
              )}
            </div>
          </div>
        </div>
      </Wrapper>
    </CSSTransition>
  );
};

export default React.forwardRef(BModal);
