import React from 'react';
import classNames from 'classnames';

import { useCountdown } from 'common/hooks/useCountdown';

import DateTimeDisplay from './DateTimeDisplay';
import ExpiredNotice from './ExpiredNotice';

import styles from './CountdownTimer.module.scss';

/* Props - <CountdownTimer />
============================================================================= */
type Props = {
  targetDate: number;
  expiredMessage?: string | React.ReactNode;
  className?: string;
};

/* <CountdownTimer />
============================================================================= */
const CountdownTimer: React.FC<Props> = ({
  targetDate,
  expiredMessage,
  className,
}) => {
  const [days, hours, minutes, seconds] = useCountdown(targetDate);

  if (days + hours + minutes + seconds <= 0) {
    return (
      <>
        {expiredMessage ? (
          <div className={className}>
            <ExpiredNotice>{expiredMessage}</ExpiredNotice>
          </div>
        ) : null}
      </>
    );
  }

  return (
    <ul className={classNames(styles.countdown, className)}>
      <DateTimeDisplay value={days} type={'Днів'} isDanger={days <= 3} />
      <DateTimeDisplay value={hours} type={'Години'} isDanger={days <= 3} />
      <DateTimeDisplay value={minutes} type={'Хвилин'} isDanger={days <= 3} />
      <DateTimeDisplay value={seconds} type={'Секунди'} isDanger={days <= 3} />
    </ul>
  );
};

export default CountdownTimer;
