import React from 'react';

/* Props <ExpiredNotice />
============================================================================= */
type Props = {
  children?: React.ReactNode;
};

/* <ExpiredNotice />
============================================================================= */
const ExpiredNotice: React.FC<Props> = ({ children }) => {
  return <div className="expired-notice">{children}</div>;
};

export default ExpiredNotice;
