import React, { useRef } from 'react';
import { Swiper, SwiperProps, SwiperRef } from 'swiper/react';
import SwiperCore, { Navigation, EffectCoverflow } from 'swiper';
import Icon, { Icons } from '../icon/Icon';

import 'swiper/scss';
import 'swiper/scss/navigation';
import 'swiper/scss/pagination';
import 'swiper/scss/effect-coverflow';

import styles from './Swiper.module.scss';

/* Local constants & types
============================================================================= */
SwiperCore.use([Navigation, EffectCoverflow]);

/* Props - <SwiperCoverflow />
============================================================================= */
type Props = {
  className?: string;
  children?: React.ReactNode;
} & SwiperProps;

/* <SwiperCoverflow />
============================================================================= */
const SwiperCoverflow: React.FC<Props> = ({
  className,
  children,
  ...props
}) => {
  const navigationPrevRef = useRef<HTMLDivElement>(null);
  const navigationNextRef = useRef<HTMLDivElement>(null);
  const swiperRef = React.useRef<SwiperRef>(null);

  return (
    <div className="relative max-w-[calc(100vw-30px)]">
      <Swiper
        className={className}
        observer={true}
        observeParents={true}
        effect="coverflow"
        ref={swiperRef}
        pagination={false}
        navigation={{
          prevEl: navigationPrevRef.current,
          nextEl: navigationNextRef.current,
        }}
        grabCursor
        // centeredSlides={true}
        // slidesPerView={3}
        // coverflowEffect={{
        //   rotate: 0,
        //   stretch: 380,
        //   depth: 100,
        //   modifier: 1,
        //   slideShadows: false,
        // }}
        // spaceBetween={4}
        // initialSlide={0}
        breakpoints={{
          320: {
            coverflowEffect: {
              rotate: 0,
              stretch: 80,
              depth: 200,
              modifier: 1,
              slideShadows: false,
            },
            slidesPerView: 1,
            centeredSlides: true,
          },
          768: {
            coverflowEffect: {
              rotate: 0,
              stretch: 80,
              depth: 400,
              modifier: 1,
              slideShadows: false,
            },
            slidesPerView: 2,
            centeredSlides: true,
          },
          1024: {
            coverflowEffect: {
              rotate: 0,
              stretch: 80,
              depth: 600,
              modifier: 1,
              slideShadows: false,
            },
            slidesPerView: 2,
            centeredSlides: true,
          },
          1280: {
            coverflowEffect: {
              rotate: 0,
              stretch: 80,
              depth: 800,
              modifier: 1,
              slideShadows: false,
            },
            slidesPerView: 2,
            centeredSlides: true,
          },
          1536: {
            coverflowEffect: {
              rotate: 0,
              stretch: 180,
              depth: 400,
              modifier: 1,
              slideShadows: false,
            },
            slidesPerView: 3,
            centeredSlides: true,
          },
        }}
        {...props}>
        {children}
      </Swiper>

      <div className="flex justify-center items-end gap-20 mt-8 md:mt-0">
        <div className={styles.prevBtn} ref={navigationPrevRef}>
          <Icon variant={Icons.chevronLeft} />
        </div>
        <div className={styles.nextBtn} ref={navigationNextRef}>
          <Icon variant={Icons.chevronRight} />
        </div>
      </div>
    </div>
  );
};

export default SwiperCoverflow;
