import Head from 'next/head';
import router from 'next/router';
import type { NextPage } from 'next';

import Layout from 'components/shared/layout/Layout';
import AboutCourseSection from 'components/views/landingPage/AboutCourseSection/AboutCourseSection';
import ContactUsSection from 'components/views/landingPage/ContactUsSection/ContactUsSection';
import CoursePathSection from 'components/views/landingPage/CoursePathSection/CoursePathSection';
import FAQSection from 'components/views/landingPage/FAQSection/FAQSection';
import IntroSection from 'components/views/landingPage/IntroSection/IntroSection';
import MentorSection from 'components/views/landingPage/MentorSection/MentorSection';
import ProsSection from 'components/views/landingPage/ProsSection/ProsSection';
import ReviewsSection from 'components/views/landingPage/ReviewsSection/ReviewsSection';
import StarterPackSection from 'components/views/landingPage/StarterPackSection/StarterPackSection';
import StudySection from 'components/views/landingPage/StudySection/StudySection';
import ToolsSection from 'components/views/landingPage/ToolsSection/ToolsSection';

import { useAuth } from 'common/hooks/useAuth';
import routes from 'common/utils/routes';
import { useEffect } from 'react';

/* Local constants
============================================================================= */
const DATE_START_COURSE = 'Jun 26, 2023 19:00:00';

/* Props - <Home />
============================================================================= */
type Props = {
  endCountdown: number;
};

/* <Home />
============================================================================= */
const Home: NextPage<Props> = ({ endCountdown }) => {
  const auth = useAuth();

  useEffect(() => {
    if (auth.isInitialized && auth.user && router.pathname === routes.home) {
      router.replace(routes.dashboard);
    }
  }, [auth, router]);

  // Facebook pixel initialization
  useEffect(() => {
    import('react-facebook-pixel')
      .then((x) => x.default)
      .then((ReactPixel) => {
        ReactPixel.init(process.env.NEXT_PUBLIC_FACEBOOK_PIXEL as string); // facebookPixelId
        ReactPixel.pageView();

        router.events.on('routeChangeComplete', () => {
          ReactPixel.pageView();
        });
      });
  }, [router.events]);

  return (
    <Layout>
      <Head>
        <title>Kittie.tech | Сучасні IT курси 🇺🇦</title>
      </Head>

      <IntroSection endCountdown={endCountdown} />
      <ProsSection />
      <AboutCourseSection />
      <ToolsSection />
      <StudySection />
      <CoursePathSection />
      <MentorSection />
      <StarterPackSection />
      {/* <ReviewsSection /> */}
      <FAQSection />
      <ContactUsSection />
    </Layout>
  );
};

export async function getStaticProps() {
  const endCountdown = new Date(DATE_START_COURSE).getTime();

  return {
    props: {
      endCountdown,
    },
  };
}

export default Home;
