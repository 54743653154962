import React from 'react';

import SubHeading from 'components/shared/typography/SubHeading';

/* Local constants & types
============================================================================= */
const STARTER_PACK_LIST = [
  {
    id: 1,
    text: (
      <>
        Основи <b>HTML, CSS</b>
      </>
    ),
  },
  {
    id: 2,
    text: (
      <>
        Базові знання <b>JavaScript</b> (умови, цикли, змінні)
      </>
    ),
  },
  {
    id: 3,
    text: (
      <>
        <b>2-3 вільних</b> години на добу, для лекцій і виконання домашки
      </>
    ),
  },
  {
    id: 4,
    text: (
      <>
        <b>Компьютер</b> на довільній операційній системі
      </>
    ),
  },
];

/* <StarterPackSection />
============================================================================= */
const StarterPackSection = () => {
  return (
    <section className="container relative py-8 gap-8 md:pb-20">
      <SubHeading className="mb-16 justify-center text-center md:mb-20">
        Що <b className="w-full md:w-auto">потрібно</b> &nbsp;знати?
      </SubHeading>

      <ul className="grid gap-4 items-stretch text-center md:grid-cols-2 md:gap-8 lg:grid-cols-4">
        {STARTER_PACK_LIST.map(({ id, text }) => {
          return (
            <li
              key={id}
              className="flex flex-wrap justify-center items-center p-8 min-h-[7.5rem] rounded-3xl shadow-blue">
              <span>{text}</span>
            </li>
          );
        })}
      </ul>
    </section>
  );
};

export default StarterPackSection;
