import { useState } from 'react';

const useConfirm = () => {
  const [promise, setPromise] = useState<Record<
    'resolve',
    (value: boolean) => void
  > | null>(null);

  const confirm = () =>
    new Promise((resolve, reject) => {
      setPromise({ resolve });
    });

  const handleClose = () => {
    setPromise(null);
  };

  const handleConfirm = () => {
    promise?.resolve(true);
    handleClose();
  };

  const handleCancel = () => {
    promise?.resolve(false);
    handleClose();
  };

  return [confirm, handleConfirm, handleCancel];
};

export default useConfirm;
