import { API } from 'managed-services';

/**
 * Send Email
 * @param email Client email address
 * @param gRecaptchaToken Google Recaptcha token
 * @param phone Client phone number
 * @param name Client name
 * @param message Client message
 * @param form Form Name
 */
export const sendEmail = ({
  email,
  gRecaptchaToken,
  phone,
  name,
  message,
  form,
}: {
  email: string;
  gRecaptchaToken: string;
  form: string;
  phone?: string;
  name?: string;
  message?: string;
}) =>
  API.post(`/contact`, { email, phone, gRecaptchaToken, name, message, form });
