import axios from 'axios';

/**
 * Create an Axios instance.
 */
export const API = axios.create({
  baseURL: '/api',
  headers: {
    'Content-Type': 'application/json',
    Accept: 'application/json',
  },
});
