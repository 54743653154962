import React, { useRef } from 'react';
import router from 'next/router';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import { PatternFormat } from 'react-number-format';
import { toast } from 'react-toastify';
import * as z from 'zod';
import { zodResolver } from '@hookform/resolvers/zod';

import useReCaptcha from 'common/hooks/useReCaptcha';
import {
  INVALID_EMAIL,
  INVALID_PHONE,
  INVALID_PHONE_STARTS_FROM_ZERO,
  PHONE_REGEX,
  PHONE_REGEX_STARTS_FROM_ZERO,
  REQUIRED_EMAIL,
  REQUIRED_NAME,
  REQUIRED_PHONE,
} from 'common/utils/constants';
import routes from 'common/utils/routes';
import Button from 'components/shared/buttons/Button';
import ErrorMessage from 'components/shared/form/ErrorMessage/ErrorMessage';
import Input from 'components/shared/form/Input/Input';
import Modal, { ModalRefType } from 'components/shared/modal/BModal/BModal';
import { sendEmail } from 'managed-services/contact';

/* Local constants & types
============================================================================= */
type FormData = z.infer<typeof schemaValidation>;

const schemaValidation = z.object({
  name: z.string().min(1, REQUIRED_NAME),
  email: z.string().min(1, REQUIRED_EMAIL).email(INVALID_EMAIL),
  phone: z
    .string({ required_error: REQUIRED_PHONE })
    .regex(PHONE_REGEX_STARTS_FROM_ZERO, INVALID_PHONE_STARTS_FROM_ZERO)
    .regex(PHONE_REGEX, INVALID_PHONE),
});

/* Props - <PaymentPopup />
============================================================================= */
type Props = {};

/* <PaymentPopup />
============================================================================= */
const PaymentPopup: React.FC<Props> = ({}) => {
  const ref = useRef<ModalRefType>(null);
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors, isSubmitting },
    control,
  } = useForm<FormData>({
    resolver: zodResolver(schemaValidation),
  });
  const handleRecaptchaVerify = useReCaptcha('PaymentForm');

  const handleClick = () => {
    ref.current?.toggle();
  };

  const onSubmit: SubmitHandler<FormData> = async (data: FormData) => {
    const token = await handleRecaptchaVerify();

    if (!token) {
      return;
    }

    try {
      const response = await sendEmail({
        ...data,
        gRecaptchaToken: token || '',
        form: 'Payment Popup',
      });

      if (response.status === 200) {
        // Handle success. You can change the message to whatever you want.
        toast.success(
          "Ваше повідомлення надіслано. Дякуємо, що звернулися до нас. Ми зв'яжемося з вами найближчим часом."
        );

        router.replace(routes.thankYou);
      }
    } catch (err: any) {
      console.log(err);
      // Handle errors. You can change the message to whatever you want.
      // toast.error(err.response.data.errors[0] + ': ' + err.response.statusText)
      toast.error('Ми не можемо надіслати форму зараз, спробуйте пізніше?');
    }
  };

  return (
    <>
      <Button variant="primary" onClick={handleClick}>
        Замовити
      </Button>

      <Modal
        ref={ref}
        title={
          <h3 className="text-xl font-bold xl:text-2xl">
            Привіт, давай знайомитись!
          </h3>
        }>
        <form
          action="https://formsubmit.co/meow@kittie.tech"
          onSubmit={handleSubmit(onSubmit)}>
          <fieldset className="mb-6 md:mb-8">
            <Input
              type="name"
              placeholder="Ім'я"
              autoFocus
              {...register('name', { required: true })}
            />
            <ErrorMessage error={errors.name} />
          </fieldset>
          <fieldset className="mb-6 md:mb-8">
            <Input
              type="email"
              placeholder="Ел. Пошта"
              {...register('email', { required: true })}
            />
            <ErrorMessage error={errors.email} />
          </fieldset>

          <fieldset className="mb-6 md:mb-8">
            <Controller
              render={({ field: { onChange, name, value } }) => (
                <PatternFormat
                  name={name}
                  value={value}
                  onChange={onChange}
                  customInput={Input}
                  type="tel"
                  format="+380 (##) ###-####"
                  allowEmptyFormatting
                  mask="_"
                />
              )}
              name="phone"
              control={control}
              rules={{ required: true }}
            />

            <ErrorMessage error={errors.phone} />
          </fieldset>

          <Button variant="primary" type="submit" disabled={isSubmitting}>
            {!isSubmitting ? 'Надіслати' : 'Надсилаємо...'}
          </Button>
        </form>
      </Modal>
    </>
  );
};

export default PaymentPopup;
