import React, { useCallback } from 'react';
import Link from 'next/link';
import { SubmitHandler, useForm } from 'react-hook-form';
import * as z from 'zod';
import { zodResolver } from '@hookform/resolvers/zod';
import { toast } from 'react-toastify';

import useReCaptcha from 'common/hooks/useReCaptcha';
import { INVALID_EMAIL, REQUIRED_EMAIL } from 'common/utils/constants';
import Button from 'components/shared/buttons/Button';
import Ellipse from 'components/shared/Ellipse/Ellipse';
import ErrorMessage from 'components/shared/form/ErrorMessage/ErrorMessage';
import Input from 'components/shared/form/Input/Input';
import SubHeading from 'components/shared/typography/SubHeading';

import styles from './ContactUsSection.module.scss';
import { sendEmail } from 'managed-services/contact';
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';

/* Local constants & types
============================================================================= */
type FormData = z.infer<typeof schemaValidation>;

const schemaValidation = z.object({
  email: z.string().min(1, REQUIRED_EMAIL).email(INVALID_EMAIL),
});

/* <ContactUsSection />
============================================================================= */
const ContactUsSection = () => {
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors, isSubmitting, isSubmitted },
  } = useForm<FormData>({
    resolver: zodResolver(schemaValidation),
  });
  const { executeRecaptcha } = useGoogleReCaptcha();

  // Create an event handler so you can call the verification on button click event or form submit
  const handleReCaptchaVerify = useCallback(async () => {
    if (!executeRecaptcha) {
      console.error('Execute recaptcha not yet available');
      return;
    }

    const token = await executeRecaptcha('ContactUsForm');
    // Do whatever you want with the token
    return token;
  }, [executeRecaptcha]);

  const onSubmit: SubmitHandler<FormData> = async (data: FormData) => {
    const token = await handleReCaptchaVerify();

    if (!token) {
      return;
    }

    try {
      const response = await sendEmail({
        ...data,
        gRecaptchaToken: token || '',
        form: 'Contact Us Section/Subscribe',
      });

      if (response.status === 200) {
        // Handle success. You can change the message to whatever you want.
        toast.success('Ви успішно підписалися на розсилку!');
        // Reset the form after successful submission
        reset();
      }
    } catch (err: any) {
      console.log(err);
      // Handle errors. You can change the message to whatever you want.
      // toast.error(err.response.data.errors[0] + ': ' + err.response.statusText)
      toast.error('Ми не можемо надіслати форму зараз, спробуйте пізніше?');
    }
  };

  return (
    <section className="container relative py-10 grid grid-flow-row-dense md:py-20 lg:grid-cols-12">
      <div className="mb-6 p-8 bg-background shadow-blue rounded-3xl md:mb-10 lg:col-span-7 lg:p-16 lg:pt-8">
        <SubHeading className="mb-6">
          <b className="w-full">Kittie</b> на зв'язку
        </SubHeading>

        <p className="mb-8">
          Якщо не певні, чи підходить вам курс, залиште свій Email, та слідкуйте
          за оновленнями. Надсилаємо тільки актуальну інформацію
        </p>

        <div className="lg:w-2/3">
          {!isSubmitted ? (
            <form
              action="https://formsubmit.co/meow@kittie.tech"
              onSubmit={handleSubmit(onSubmit)}>
              <fieldset className="mb-6 md:mb-8">
                <Input
                  type="email"
                  placeholder="Ел. Пошта"
                  {...register('email', { required: true })}
                />
                <ErrorMessage error={errors.email} />
              </fieldset>

              <Button variant="primary" type="submit" disabled={isSubmitting}>
                {!isSubmitting ? 'Підписатися' : 'Оброблюємо...'}
              </Button>
            </form>
          ) : (
            <p className="text-bold text-xl font-bold">
              Ласкаво прошу, до нашого клубу АйТівців. Будемо тримати тебе в
              курсі цікавих новин!
            </p>
          )}
        </div>
      </div>

      <div className="p-8 bg-background shadow-red rounded-3xl  lg:col-start-6 lg:row-span-1 lg:col-span-7 lg:-mt-44 lg:p-16">
        <p className="text-center lg:text-2xl">
          Приєднуйся до нашого{' '}
          <a
            className="underline hover:text-olive-green"
            href="https://t.me/frontend_kittie">
            <b>телеграм</b>
          </a>{' '}
          каналу і будь у курсі подій
        </p>
        <SubHeading className="justify-center mb-6">
          <b>Є питання?</b>
        </SubHeading>

        <div className="flex flex-col flex-wrap justify-center mb-6 md:flex-row md:gap-x-8">
          <p className="text-xl text-center">
            <a href="tel:+380960722786">
              <b>+38&nbsp;(096)&nbsp;072-27-86</b>
            </a>
          </p>

          <p className="text-xl text-center">
            <a href="mailto:meow@kittie.tech">
              <b>meow@kittie.tech</b>
            </a>
          </p>
        </div>

        <Link href="https://t.me/kittietech" passHref>
          <Button variant="primary">Написати</Button>
        </Link>
      </div>

      <Ellipse className={styles.ellipse1} variant="blue" size="lg" />
      <Ellipse className={styles.ellipse2} variant="red" size="md" />
      <Ellipse className={styles.ellipse3} variant="red" size="sm" />
    </section>
  );
};

export default ContactUsSection;
