import React from 'react';
import classNames from 'classnames';

import styles from './RoundedCard.module.scss';

/* Props - <RoundedCard />
============================================================================= */
type Props = {
  id: number;
  title: string;
  text: string;
  active?: boolean;
  className?: string;
};

/* <RoundedCard />
============================================================================= */
const RoundedCard: React.FC<Props> = ({
  id,
  title,
  text,
  active,
  className,
}) => {
  return (
    <div
      className={classNames(styles.card, className, {
        [styles.active]: active,
      })}>
      <header className={styles.header}>
        <div className={styles.title}>{id}й тиждень</div>
        <h3 className={styles.subTitle}>{title}</h3>
      </header>
      <div className={styles.body}>{text}</div>
    </div>
  );
};

export default RoundedCard;
