import React from 'react';

import Accordion from 'components/shared/accordion/Accordion';
import SubHeading from 'components/shared/typography/SubHeading';
import { AccordionTabType } from 'components/shared/accordion/AccordionTab';

/* Local constants & types
============================================================================= */
const ACCORDION_LIST: AccordionTabType[] = [
  {
    id: '0',
    header: 'Чому саме ваш курс?',
    body: 'Ми пропонуємо тільки практику. Курс побудований таким чином, щоб протягом вашого перших місяців роботи, ви могли повертатися до курсу і брати ідеї реалізації архітектури. Поки інші розмовляють, ми вже робимо',
    show: true,
  },
  {
    id: '1',
    header: 'Хочеш навчатися, але взагалі нічого не знаєш?',
    body: 'Пиши сюди ⇒ meow@kittie.tech, ментор підкаже, що потрібно для старту. Якщо встигнеш підготуватися, потрапиш у цей набір. Якщо ні, резервуй місце в наступному за поточною ціною (як щасливчик!)',
  },
  {
    id: '2',
    header: 'Що я отримаю по закінченню курсу?',
    body: "На момент закінченя курсу, ви будете мати власний розроблений проект, який ви додасте до вашого резюме. Пройдете інтерв'ю з ментором і отримаєте фідбек, щодо подальшого навчання. За прилежне навчання, ви отримуєте круті NFT картки",
  },
  {
    id: '3',
    header: 'Якщо я вже розробник, що я отримаю?',
    body: 'Якщо ви вже маєте досвід у програмування, ви можете стати Full Stack розробником. Це додасть неабиякої ваги до вашого резюме і ви завжди матиметиме роботу',
  },

  {
    id: '4',
    header: 'Чи потрібні мені знання англійського, щоб пройти курс?',
    body: (
      <>
        Читання статей з Google Translate - це необхідний мінімум. <br /> <br />
        Навчання та відеозаписи, ментор проводить українською мовою. Матеріали
        для самостійної роботи вибірково англомовні, адже в першоджерелах більше
        користі
      </>
    ),
  },

  {
    id: '5',
    header: 'Чи можу я повернути грощі?',
    body: "Так, в перші 7 днів. Ми розуміємо, що суб'єктивно курс та темп можуть не підійти",
  },
  {
    id: '6',
    header: 'Якщо в мене зникне світло і я відстану від основної програми?',
    body: 'Ми розуміємо, що форс мажорні ситуації трапляються. Матеріали курси і чат зі студентами будуть вам доступні, протягом усього часу і ви зможете в будь який момент повернутися і продовжити навчання. Також ми маємо внутрішній telegram чат, де ви зможете задати питання ментору',
  },
];

/* <FAQSection />
============================================================================= */
const FAQSection = () => {
  return (
    <section className="py-10 md:py-20 space-background">
      <div className="container">
        <SubHeading className="mb-16">
          <b className="w-full md:mb-4">Питання-відповідь</b> розвічуємо усі
          страхи
        </SubHeading>

        <Accordion tabs={ACCORDION_LIST} />
      </div>
    </section>
  );
};

export default FAQSection;
