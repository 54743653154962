import React, { useState } from 'react';
import { SwiperSlide } from 'swiper/react';
import Swiper from 'swiper';

import SwiperCoverflow from 'components/shared/carousel/SwiperCoverflow';
import SubHeading from 'components/shared/typography/SubHeading';
import Ellipse from 'components/shared/Ellipse/Ellipse';
import RoundedCard from 'components/shared/cards/RoundedCard';

import styles from './CoursePathSection.module.scss';

/* Local constants & types
============================================================================= */
const COURSE_PATH_LIST = [
  {
    id: 0,
    title: 'Основи основ',
    text: 'Встановлюємо необхідні для роботи програми. Згадаємо основи HTML, CSS, JavaScript,  Git',
  },
  {
    id: 1,
    title: 'Будуємо архітектуру',
    text: 'Починаємо розробку додатку. Конфігуруємо наш додаток і верстаємо макет',
  },
  {
    id: 2,
    title: 'Динамічний додаток',
    text: 'Додаємо fetch, роути у наш додаток, ',
  },
  {
    id: 3,
    title: 'Зберігаємо дані',
    text: 'Працюємо з API та базою даних Firebase. Додаємо авторизацію користувача',
  },
  {
    id: 4,
    title: 'MVP готовий',
    text: 'Рефакторим, та причісуємо наш додаток',
  },
  {
    id: 5,
    title: 'Go live',
    text: 'Тестуємо, публікуємо додаток у продакшен на vercel',
  },
  {
    id: 6,
    title: 'Ви прийняті',
    text: "Пройдемо інтерв'ю, підведемо підсумки та роздамо подарунки",
  },
];

/* <CoursePathSection />
============================================================================= */
const CoursePathSection = () => {
  const [currentIndex, setCurrentIndex] = useState<number>(0);

  const updateIndex = (swiperInstance: Swiper) => {
    if (swiperInstance === null) return;
    const currentSlide = swiperInstance?.activeIndex;
    setCurrentIndex(currentSlide);
  };

  return (
    <section className="container relative grid grid-flow-row-dense grid-cols-12 items-center md:py-20 z-1">
      <SubHeading className="md:mb-0">
        <b>Програма</b> курсу
      </SubHeading>
      <div className="col-span-12">
        <SwiperCoverflow
          initialSlide={currentIndex}
          onActiveIndexChange={updateIndex}
          className="md:!-mx-12 md:!p-12 sm:!-mx-8 sm:!px-8 lg:!m-0">
          {COURSE_PATH_LIST.map(({ id, title, text }, index) => {
            return (
              <SwiperSlide key={id} className="!h-min self-center">
                <RoundedCard
                  active={currentIndex === index}
                  className="w-full h-60 text-center md:w-[25rem] xl:w-[30rem] md:h-48 md:text-left"
                  id={id}
                  title={title}
                  text={text}
                />
              </SwiperSlide>
            );
          })}
        </SwiperCoverflow>
      </div>

      <Ellipse className={styles.ellipse1} variant="blue" size="lg" />
      <Ellipse className={styles.ellipse2} variant="red" size="md" />
      <Ellipse className={styles.ellipse3} variant="red" size="sm" />
    </section>
  );
};

export default CoursePathSection;
