import React from 'react';
import classNames from 'classnames';

import SubHeading from 'components/shared/typography/SubHeading';

import styles from './ToolsSection.module.scss';

/* Local constants & types
============================================================================= */
const TOOLS_LIST = [
  { id: 1, name: 'Tailwind.css', icon: 'tailwind' },
  { id: 2, name: 'Typescript', icon: 'typescript' },
  { id: 3, name: 'React.js', icon: 'react' },
  { id: 4, name: 'Next.js', icon: 'next' },
  { id: 5, name: 'Git', icon: 'git' },
  { id: 6, name: 'Firebase', icon: 'firebase' },
  { id: 7, name: 'React Testing Library', icon: 'rtl' },
];

/* <ToolsSection />
============================================================================= */
const ToolsSection = () => {
  return (
    <section className="py-10 space-background md:-mt-[10rem] md:pt-44">
      <div className="container relative flex flex-col justify-center items-center md:flex-row md:w-[35rem] md:h-[35rem] lg:w-[54rem] lg:h-[54rem] xl:w-[73rem] xl:h-[73rem]">
        <SubHeading className="text-center justify-center">
          <b className="mb-4 md:mb-12">Інструменти,</b>
          <span className="w-full">які ви вивчите</span> <span>на курсі:</span>
        </SubHeading>
        <ul className={styles.list}>
          {TOOLS_LIST.map(({ id, name, icon }, index) => {
            return (
              <li
                key={id}
                className={classNames(styles.listItem, {
                  [styles.bottom]: index > TOOLS_LIST.length / 2,
                })}>
                {name}
              </li>
            );
          })}
        </ul>
      </div>
    </section>
  );
};

export default ToolsSection;
