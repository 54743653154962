import React from 'react';
import classNames from 'classnames';

import { getDoubleZeroDate } from 'common/utils/date';

import styles from './CountdownTimer.module.scss';

/* Props - <DateTimeDisplay />
============================================================================= */
type Props = {
  value: number;
  type: string;
  isDanger: boolean;
};

/* <DateTimeDisplay />
============================================================================= */
const DateTimeDisplay: React.FC<Props> = ({ value, type, isDanger }) => {
  return (
    <li className={classNames(styles.card, { [styles.danger]: isDanger })}>
      <p className={styles.time} suppressHydrationWarning={true}>
        {getDoubleZeroDate(value)}
      </p>
      <span className={styles.text}>{type}</span>
    </li>
  );
};

export default DateTimeDisplay;
