import React, { useState } from 'react';
import classNames from 'classnames';
import dynamic from 'next/dynamic';

import Ellipse from 'components/shared/Ellipse/Ellipse';
import SubHeading from 'components/shared/typography/SubHeading';

const ReactPlayer = dynamic(() => import('react-player/lazy'), { ssr: false });

import styles from './AboutCourseSection.module.scss';

/* Local constants & types
============================================================================= */
const LICENSE_SERVER = 'https://widevine-proxy.appspot.com/proxy';
const MPD_FILE =
  'https://storage.googleapis.com/shaka-demo-assets/angel-one/dash.mpd';
const VIDEO_THUMBNAIL =
  'https://upload.wikimedia.org/wikipedia/commons/a/a7/Big_Buck_Bunny_thumbnail_vlc.png';

/* <AboutCourseSection />
============================================================================= */
const AboutCourseSection = () => {
  return (
    <section className="container relative grid grid-flow-row-dense items-center py-8 gap-4 lg:grid-cols-12 xl:py-20 xl:gap-16 z-1">
      <div className="lg:col-span-5 xl:col-span-6">
        <SubHeading>
          <span className="w-min md:w-auto mr-2">Трохи про</span>{' '}
          <b className="md:w-full">курс</b>
        </SubHeading>

        {/* <p>
          Основна ідея проекту, по закінченю курсу, я проводжу зі своїми
          студентами інтерв'ю 1&nbsp;to&nbsp;1, де задаю питання з компаній
          гігантів(EPUM, SoftServe, etc). Таким чином, студент знатиме, що його
          очікує, я вкажу що треба довчити і матиме більше шансів отримати
          вакансію на його першій роботі.
        </p> */}

        <p>
          За 6 тижнів, ми з нуля напишимо онлайн чат на React.js та Next.js.
          Повторимо основи HTML, CSS, JavaScript, Git. Побудуємо архітектуру
          додатку та попрацюємо з API. Додамо тести та опублікуємо наш додаток у
          vercel.
        </p>

        <p>
          Курс побудований таким чином, що рівень складності підвищується
          поступово, а умови, наближені до реальних. Ти будеш приймати участь у
          обговорені, ревьювити код інших учасників та слідувати дедлайнам
        </p>

        <p>
          По вихідних, ментор проводить з групою онлайн ретроперспективу
          (обговорюємо, плануємо, відповідаємо на питання, даємо фідбек).
        </p>
        {/* <p>
          За тиждень до початку курсу, ти отримаєш посилання до власного
          кабінету, де будуть відбуватися заняття наступні 6 тижнів.
        </p> */}
      </div>
      <div className="relative lg:col-span-7 xl:col-span-6">
        {/* <VideoPlayerPopup /> */}

        <div className="relative pb-[56.25%] md:my-10">
          <ReactPlayer
            className="absolute top-0 left-0"
            width="100%"
            height="100%"
            url="https://youtu.be/sL61hf4ORdw"
          />
        </div>

        <Ellipse
          className={classNames('md:hidden', styles.ellipse1)}
          variant="red"
          size="sm"
        />
        <Ellipse
          className={classNames('md:hidden', styles.ellipse2)}
          variant="red"
          size="md"
        />
      </div>
    </section>
  );
};

export default AboutCourseSection;
