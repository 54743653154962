import React from 'react';
import classNames from 'classnames';
import Image from 'next/image';

import Ellipse from 'components/shared/Ellipse/Ellipse';
import List from 'components/shared/list/List';

import SubHeading from 'components/shared/typography/SubHeading';

import styles from './ProsSection.module.scss';

/* Local constants & types
============================================================================= */
const BENEFITS_LIST = [
  { id: 1, name: 'Кваліфікований викладач' },
  { id: 2, name: 'Якісні матеріали курса' },
  { id: 3, name: 'За досягнення отримуєте NFT картки' },
  { id: 4, name: 'Рівень міжнародних компаній гігантів' },
];

/* <ProsSection />
============================================================================= */
const ProsSection = () => {
  return (
    <section className="container relative grid grid-flow-row-dense py-8 md:gap-x-8 md:grid-cols-12 lg:py-20 xl:gap-x-24 xl:py-32 z-1">
      <div className="md:col-span-7 md:order-1 2xl:col-span-8">
        <SubHeading>
          <span className="mr-2 md:mr-8 w-min">Чому саме</span> <b>цей курс</b>
        </SubHeading>
        <p>
          Основна ідея проекту, по закінченю курсу, ментор проводить зі своїми
          студентами інтерв'ю 1 to 1, де задає питання з компаній гігантів
          (EPUM, SoftServe, etc). Таким чином, ти вже будеш знати, що тебе
          очікує на твоєму першому інтвервью і матимеш усі шанси отримати свій
          перший офер до омріяної компанії.
        </p>

        <p>
          Ментор пройшов цей шлях самостійно і знає, як пройти цей шлях
          найшвидше та найефективніше. Тільки дієві поради та приклади, які
          працюють на реальних проектах.
        </p>

        <p>
          Із бонусів, по закінченню курсу, наполегливі студенти отримають NFT
          картки за особливі досягнення (за креативність, за командну роботу та
          ін).
        </p>
      </div>

      <div className="relative w-72 h-96 m-auto md:w-full md:col-span-5 2xl:col-span-4">
        <figure className={classNames(styles.imgNft, styles.imgNft1)}>
          <Image
            className="object-cover"
            src="/img/rebel.jpg"
            width="500"
            height="500"
            alt="NFT 'Бунтар'"
          />
        </figure>
        <figure className={classNames(styles.imgNft, styles.imgNft2)}>
          <Image
            className="object-cover"
            src="/img/hired.jpg"
            width="500"
            height="500"
            alt="NFT 'Ви прийняті'"
          />
        </figure>
        <figure className={classNames(styles.imgNft, styles.imgNft3)}>
          <Image
            className="object-cover"
            src="/img/hacker.jpg"
            width="500"
            height="500"
            alt="NFT 'Хакер'"
          />
        </figure>
      </div>

      <List
        className="md:col-span-12 md:mt-12 lg:mt-18 xl:mt-28"
        items={BENEFITS_LIST}
      />

      <Ellipse className={styles.ellipse1} variant="blue" size="lg" />
      <Ellipse
        className={classNames('hidden md:block', styles.ellipse2)}
        variant="red"
        size="md"
      />
      <Ellipse
        className={classNames('hidden md:block', styles.ellipse3)}
        variant="red"
        size="sm"
      />
    </section>
  );
};

export default ProsSection;
