import React from "react";
import classNames from "classnames";

import styles from "./List.module.scss";

/* Local constants & types
============================================================================= */
export type ListItemType = {
  id: React.Key;
  name: string;
}

/* Props - <List />
============================================================================= */
type Props = {
  items: ListItemType[];
  className?: string;
}

/* <List />
============================================================================= */
const List: React.FC<Props> = ({ className, items }) => {
  return (
    <ul className={classNames("flex flex-col flex-wrap gap-8 lg:flex-row lg:gap-x-0 2xl:flex-nowrap 2xl:gap-8", styles.list, className)}>
      {items.map((item) => (
        <li className="lg:w-1/2 xl:w-1/3 2xl:w-auto" key={item.id}>{item.name}</li>
      ))}
    </ul>
  )
}

export default List;