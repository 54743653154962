import React from 'react';
import classNames from 'classnames';
import Image from 'next/image';

import SubHeading from 'components/shared/typography/SubHeading';

import styles from './MentorSection.module.scss';

/* Local constants & types
============================================================================= */
const ACHIEVEMENTS_LIST = [
  { id: 1, name: '8 років практики у IT;' },
  {
    id: 2,
    name: "З'їв собаку на бізнес процесах і знає, що потрібно новачкам;",
  },
  { id: 3, name: 'Розуміється у FrontEnd архітектурі і React.js фреймворку;' },
  { id: 4, name: 'Продовжує навчання та ставить нові виклики для себе;' },
];

/* <ProsSection />
============================================================================= */
const MentorSection = () => {
  return (
    <section
      className={classNames(
        'relative py-20 space-background',
        styles.bgCircles
      )}>
      <div className="container relative grid grid-flow-row-dense gap-8 justify-center md:grid-cols-12 lg:pt-28 lg:pb-60">
        <SubHeading className="mb-80 z-1 md:mb-44 md:col-start-6 lg:col-start-7 xl:col-start-6 2xl:col-start-5">
          Про <b className="md:w-full">ментора:</b>
        </SubHeading>

        <figure className="relative mb-8 w-72 z-1 md:col-span-6 md:row-start-2 md:mt-8 lg:col-span-7 lg:mt-28 lg:w-auto xl:col-span-6 xl:mt-36 2xl:col-span-5">
          <Image
            className="absolute left-6 right-0 bottom-full mx-auto -mb-[4.725rem] z-1 lg:-mb-[8.425rem] xl:-mb-[9rem]"
            src="/img/lecturer.png"
            width={541}
            height={746}
            alt="Artem Shcherbakov"
          />
          <figcaption
            className={classNames(
              'p-6 w-full font-jedi tracking-widest whitespace-nowrap lg:p-10 lg:text-xl',
              styles.figcaption
            )}>
            <span className="relative z-1">
              <b className="block mb-4 text-olive-green text-shadow text-xl lg:text-3xl">
                Артем Щербаков
              </b>
              Software Engineer
            </span>
          </figcaption>
        </figure>

        <ul
          className={classNames(
            'md:col-span-6 md:row-span-2 md:mt-48 lg:col-span-none lg:mt-0',
            styles.list
          )}>
          {ACHIEVEMENTS_LIST.map(({ id, name }) => {
            return (
              <li key={id} className={styles.listItem}>
                {name}
              </li>
            );
          })}
        </ul>
      </div>
    </section>
  );
};

export default MentorSection;
