import React from 'react';
import Image from 'next/image';
import classNames from 'classnames';

import Ellipse from 'components/shared/Ellipse/Ellipse';
import SubHeading from 'components/shared/typography/SubHeading';

import styles from './StudySection.module.scss';

/* Local constants & types
============================================================================= */
const TODO_LIST = [
  {
    id: 1,
    name: 'Навчаєшся у власному кабінеті з відео лекціями, конспектами',
    icon: 'computer',
  },
  { id: 2, name: 'Дивишся лекції у будь який вільний час', icon: 'clock' },
  { id: 3, name: 'Спілкуєшся і задаєш питання у групі', icon: 'bubble-chat' },
  {
    id: 4,
    name: 'Раз на тиждень зідзвонюєшся з ментором та групою',
    icon: 'presentation',
  },
  { id: 5, name: 'Здаєш домашку ментору на перевірку', icon: 'document' },
];

/* <StudySection />
============================================================================= */
const StudySection = () => {
  return (
    <section className="container relative py-8 gap-8 xl:py-20">
      <SubHeading className="justify-center text-center">
        <span className="md:w-full">Як проходить</span>{' '}
        <b className="w-full">навчання:</b>
      </SubHeading>

      <ul className="grid grid-flow-row-dense md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-5 gap-8 items-stretch justify-center text-center">
        {TODO_LIST.map(({ id, name, icon }) => {
          return (
            <li key={id} className="p-8 rounded-3xl border border-gray-400">
              <Image
                className="max-h-9 mx-auto mb-4"
                src={`/icons/${icon}.svg`}
                width={36}
                height={36}
                alt={icon}
                aria-hidden="true"
              />
              {name}
            </li>
          );
        })}
      </ul>

      <Ellipse
        className={classNames('hidden md:block', styles.ellipse1)}
        variant="red"
        size="sm"
      />
      <Ellipse
        className={classNames('hidden md:block', styles.ellipse2)}
        variant="red"
        size="md"
      />
    </section>
  );
};

export default StudySection;
