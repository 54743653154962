import React from 'react';
import Image from 'next/image';

import CountdownTimer from 'components/shared/Timer/CountdownTimer';
import Ellipse from 'components/shared/Ellipse/Ellipse';
import PaymentPopup from './PaymentPopup';

/* Props - <IntroSection />
============================================================================= */
type Props = {
  endCountdown: number;
};

/* <IntroSection />
============================================================================= */
const IntroSection: React.FC<Props> = ({ endCountdown }) => {
  return (
    <section className="container grid grid-flow-row-dense items-center py-8 md:grid-cols-12 xl:pb-20">
      <div className="z-40 md:col-span-3">
        <h1 className="mb-10 max-w-min font-jedi text-lg leading-10 text-justify sm:text-2xl md:text-[1rem] lg:text-[1.35rem] xl:text-3xl">
          <span className="text-3xl text-shadow sm:text-5xl md:text-4xl lg:text-5xl xl:text-[4.25rem]">
            Переходь
          </span>{' '}
          <span className="block text-justify tracking-[1em] sm:mt-4 sm:mb-3 xx:mt-5">
            на&nbsp;темну
          </span>{' '}
          <span className="text-7xl text-shadow sm:text-8xl md:text-7xl lg:text-8xl xl:text-[8.125rem]">
            тему
          </span>
        </h1>
        <p className="mb-6 lg:text-xl">
          6-ти тижневий інтенсив. Курси для{' '}
          <b className="uppercase">Front&nbsp;End</b> розробників по{' '}
          <b>React.js</b>.
        </p>
        <p>Курси ведуться на українській мові.</p>
      </div>

      <figure className="relative m-auto max-w-xs md:max-w-none md:col-span-4 lg:col-span-5">
        <Image
          className="relative object-fit z-10"
          src="img/cat-jedi.svg"
          width="560"
          height="657"
          alt=""
        />
        <Ellipse className="rotate-90" variant="blue" size="lg" />
        <Ellipse
          className="top-auto right-auto translate-y-1/4 md:translate-y-1/2 lg:translate-y-1/4 xl:translate-y-0"
          variant="red"
          size="md"
        />
      </figure>

      <div className="text-center z-10 md:col-span-5 lg:col-span-4">
        <p className="mb-4 text-xl font-bold xl:text-3xl">
          Ціна курсу <b>5&nbsp;500грн</b>{' '}
          <s className="text-sm">(11&nbsp;000грн)</s>
        </p>
        <p className="mb-8 xl:text-xl">для перших 10 місць </p>
        {endCountdown - new Date().getTime() > 0 ? (
          <>
            <CountdownTimer className="mb-8" targetDate={endCountdown} />
            <p className="block mb-4 p-2.5 rounded-lg bg-black uppercase text-center shadow-blue">
              залишилось <b>8 місць</b>
            </p>
          </>
        ) : (
          <div className="block mb-8 p-2.5 rounded-lg bg-black uppercase text-center shadow-blue">
            <p className="font-jedi text-xl tracking-[0.35em] leading-relaxed text-danger">
              Набір у літню группу завершено
            </p>
            <p>Зроби передзамовлення у осіню группу, за старими тарифами.</p>
            <p>
              <b>Початок 28 серпня</b>
            </p>
          </div>
        )}

        <PaymentPopup />
        {endCountdown - new Date().getTime() > 0 && (
          <p className="mt-4">
            <b>* Початок курсу 26 червня</b>
          </p>
        )}
      </div>
    </section>
  );
};

export default IntroSection;
